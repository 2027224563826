import axios from "axios";

export function getOrder(idOrder) {
    return axios.get(process.env.VUE_APP_ZS_ORDER + '/cart/' + idOrder + "/")
}

export function updateStatus(status, idOrder, labelUrl, shipNum) {
    return axios.put(process.env.VUE_APP_ZS_ORDER + '/cart/' + idOrder + "/status", {
        status: status,
        labelUri: labelUrl,
        shippingNumber: shipNum
    })
}

export function getAllOrders() {
    return axios.get(process.env.VUE_APP_ZS_ORDER + '/cart/all')
}

export function searchForName(searchKey) {
  return axios.get(process.env.VUE_APP_ZS_ORDER + '/cart/all/' + searchKey)
}
