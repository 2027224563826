import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/create-product',
    name: 'CreateProduct',
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateProduct.vue')
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import(/* webpackChunkName: "about" */ '../views/CategorieHome.vue')
  },
  {
    path: '/order/:id',
    name: 'OrderDetail',
    component: () => import('../views/Order.vue')
  },
  {
    path: '/promos',
    name: 'Promo',
    component: () => import('../views/Promo.vue')
  },
  {
    path: '/attributes',
    name: 'Attributes',
    component: () => import('../views/Attributs.vue')
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/ProductList.vue')
  },
  {
    path: '/products/one/:id',
    name: 'ProductsView',
    component: () => import('../views/CreateProduct.vue')
  },
  {
    path: '/order-list',
    name: 'OrderListView',
    component: () => import('../views/OrderList.vue')
  },
  {
    path: '/page',
    name: 'Page',
    component: () => import('../views/Page.vue')
  },
  {
    path: '/page/:id',
    name: 'Page',
    component: () => import('../views/Page.vue')
  },
  {
    path: '/create-page',
    name: 'CreatePage',
    component: () => import('../views/CreatePage.vue')
  },
  {
    path: '/page-list',
    name: 'PageList',
    component: () => import('../views/PageList.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
