import {
  createToastInterface,
  EventBus,
  toastInjectionKey
} from 'vue-toastification'
// This will be the global event bus
const globalEventBus = new EventBus()
// Returns an interface to the global toast component tree
export function useGlobalToast () {
  return createToastInterface(globalEventBus)
}
// Use this as a plugin to register instance and injected toasts
export function provideGlobalToast (app, options) {
  // Create the separate component tree
  const toast = createToastInterface({ ...options, eventBus: globalEventBus })
  // Provide using Vue dependency injection
  app.provide(toastInjectionKey, toast)
}
