<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          ZSolutions E-commerce : Chatelier3D
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Accueil</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Catalogue
              </a>
              <ul class="dropdown-menu">
                <li><router-link tag="a" to="/create-product" class="dropdown-item">Nouvelle entrée produit</router-link>
                </li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><router-link tag="a" to="/categories" class="dropdown-item">Catégories</router-link></li>
                <li><router-link tag="a" to="/products" class="dropdown-item">Produits</router-link></li>
                <li><router-link tag="a" to="/promos" class="dropdown-item">Promotions</router-link></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Commandes
              </a>
              <ul class="dropdown-menu">
                <li><router-link tag="a" to="/order-list" class="dropdown-item">Commandes</router-link></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Personnalisation
              </a>
              <ul class="dropdown-menu">
                <li><router-link tag="a" to="/create-page" class="dropdown-item">Créer une page</router-link></li>
                <li><router-link tag="a" to="/page-list" class="dropdown-item">Pages</router-link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <router-view />
</template>

<style>
#app {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
