<template>
  <div class="home">
    <div class="container w-100 mt-3">
      <div class="mx-auto text-center">
          <h2>Tâches</h2>
      </div>
      <div class="d-flex justify-content-center">
        <div style="margin-left: 2px; background-color: red" class="order-to-shipp d-flex justify-content-around">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
              </svg>
            </div>
            <h4> {{toPrepareOrders.length}} Commandes à préparer</h4>
        </div>
        <div style="margin-left: 5px; background-color: coral" class="order-to-shipp d-flex">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16">
              <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
            </svg>
          </div>
          <h4> {{toShipOrders.length}} Commandes à éxpedier</h4>
        </div>
        <div style="margin-left: 5px; background-color: #42b983" class="order-to-shipp d-flex">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16">
              <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
            </svg>
          </div>
          <h4> {{shippedOrders.length}} Commandes expédiées</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAllOrders} from '../services/orderService';
export default {
  name: 'Home',
  data() {
    return {
      toShipOrders: [],
      toPrepareOrders: [],
      shippedOrders: []
    }
  },
  created() {
    getAllOrders()
      .then(orders => {
        const orderData = orders.data;
        this.toShipOrders = orderData.filter(o => o.orderStatus === 'PREPARATION');
        this.toPrepareOrders = orderData.filter(o => o.orderStatus === 'PAID')
        this.shippedOrders = orderData.filter(o => o.orderStatus === 'SHIPPED')
      })
  },
  components: {}
}
</script>
<style lang="css">
  .order-to-shipp {
    color: white;
    padding: 5px;
  }
</style>
